import React, {useContext, useState, useEffect} from "react";
import PageWrapper from "../PageWrapper";
import styled from "styled-components";
import ButtonWrapper from "../../styles/ButtonWrapper";
import Button from "../../styles/Button";
import {Page} from "../../styles/Page";
import { RgbColorPicker } from "react-colorful"
import { changeBrightnessRequest, changeColorRequest, customRequest, bakRequest, timerRequest } from "../../../shared/network/LedsRequests";
import {MeContext} from "../../../shared/context/MeContext";
import {isAdmin, isFeut } from "../../../util/Helpers";
import {UsersContext} from "../../../shared/context/UsersContext";

const NoButton = styled.div`
    margin: 2px;
    text-align: center;
    color: white;
`;

const Container = styled.div`
    display: flex;
`;

const CheckboxContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const Col = styled.div`
    flex: 50%;
    margin: 4px;
`;


const Center = styled.div`
    display: flex;
    justify-content: center;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 10em;
    gap: 0.5em;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 4px 8px 2px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    &:hover {
        filter: brightness(0.8);
    }
`;

const Image = styled.div`
    border-radius: 30px 30px 0 0;
    height: 80%;
    align-self: stretch;
    flex-shrink: 0;
    background: url(${props => props.src}) no-repeat center;
    background-size: cover;
`;

const TimerPopup = ({ closePopup }) => {
    const [seconds, setSeconds] = useState('');

    const handleSecondsChange = (event) => {
        setSeconds(event.target.value);
    };

    const [minutes, setMinutes] = useState('');

    const handleMinutesChange = (event) => {
        setMinutes(event.target.value);
    };

    const handleSubmit = () => {
        // Call your timer request function here
        timerRequest(Number(minutes) * 60 + Number(seconds));
        closePopup(); // Close the popup after submission
    };

    return (
        <div style={popupStyle}>
            <div style={popupContentStyle}>
                <h2>Timer</h2>
                <input 
                    type="number" 
                    value={minutes} 
                    onChange={handleMinutesChange}
                    min="00"
                    max="59"
                    placeholder="00"
                    style={{ padding: '10px', marginRight: '5px', marginLeft: '60px', fontSize: '18px' }} 
                />:
                <input 
                    type="number" 
                    value={seconds} 
                    onChange={handleSecondsChange}
                    min="00"
                    max="59"
                    placeholder="00"
                    style={{ padding: '10px', marginRight: '60px', marginLeft: '5px', fontSize: '18px' }} 
                />
                <div style={buttonWrapperStyle}>
                    <button onClick={handleSubmit} style={{fontSize: '18px'}}>Start</button>
                    <button onClick={closePopup} style={{fontSize: '18px'}}>Sluit</button>
                </div>
            </div>
        </div>
    );
};

const popupStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const popupContentStyle = {
    background: '#fff',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
    fontSize: '18px',
};

const buttonWrapperStyle = {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-evenly',
};

const LedsPage = () => {

    const { me, loadMe } = useContext(MeContext);

    const { users, loadUsers} = useContext(UsersContext);

    useEffect(() => {
        loadMe();
        loadUsers();
    }, []);

    // For led strip specifics
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(!show);
    };

    const [timerPopup, setTimerPopup] = useState(false);

    const toggleTimerPopup = () => {
        setTimerPopup(!timerPopup);
    };

    const [adminProgrammes, setAdminProgrammes] = useState(false);

    const toggleAdminProgrammes = () => {
        setAdminProgrammes(!adminProgrammes);
    };

    const [isCheckedMain, setIsCheckedMain] = useState(true);

    const handleOnChangeMain = () => {
      setIsCheckedMain(!isCheckedMain);
    };

    const [isCheckedBar, setIsCheckedBar] = useState(true);

    const handleOnChangeBar = () => {
      setIsCheckedBar(!isCheckedBar);
    };

    const [isCheckedHallway, setIsCheckedHallway] = useState(true);

    const handleOnChangeHallway = () => {
      setIsCheckedHallway(!isCheckedHallway);
    };

    const [color, setColor] = useState({ r: 255, g: 50, b: 0 });
    
    useEffect(() => {
        const timeOutId = setTimeout(() => changeColorRequest(color, isCheckedMain, isCheckedBar, isCheckedHallway), 300);
        return () => clearTimeout(timeOutId)
    }, [color])
    
    const [brightness, setBrightness] = useState(0.5)
    
    const changeBrightness = (event) => {
        setBrightness(event.target.value);
      };
      
    useEffect(() => {
        const timeOutId = setTimeout(() => changeBrightnessRequest(brightness, isCheckedMain, isCheckedBar, isCheckedHallway), 300);
        return () => clearTimeout(timeOutId)
    }, [brightness])

    const getBakChanceBasedOnRole = () => {
        if(isFeut(me)) { return 0.2 }
        if(isAdmin(me)) { return 0.1 }
        return 0.125
    }

    return (
        <PageWrapper>
            <Page>
                <Container>
                    <Col>
                        <ButtonWrapper><NoButton>Verander kleur</NoButton></ButtonWrapper>
                        <Center>
                            <RgbColorPicker color={color} onChange={setColor} />
                        </Center>
                    </Col>
                    <Col>
                        <ButtonWrapper><NoButton>Verander helderheid</NoButton></ButtonWrapper>
                        <Center>
                            <input
                                type='range'
                                onChange={changeBrightness}
                                min={0}
                                max={1}
                                step={0.005}
                                value={brightness}>
                            </input>
                        </Center>
                    </Col>
                </Container>
                
                
                <ButtonWrapper><NoButton>Selecteer een programma</NoButton></ButtonWrapper>

                <Container>
                    <Col>
                        <Card onClick={() => customRequest('rainbow')}>
                            <Image src="https://www.theglowhouse.com/images/large/RainbowNeonLight.jpg"/>
                            Regenboog
                        </Card>
                        <Card onClick={() => bakRequest(getBakChanceBasedOnRole())}>
                            <Image src="https://www.grolsch.com/files/pilsner-page/pilsner.webp"/>
                            Bak
                        </Card>
                        <Card onClick={() => customRequest('nl')}>
                            <Image src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Flag_of_the_Netherlands.svg/1280px-Flag_of_the_Netherlands.svg.png"/>
                            Nederland
                        </Card>
                    </Col>
                    <Col>
                        <Card onClick={() => customRequest('movie')}>
                            <Image src="https://cdn.cnn.com/cnnnext/dam/assets/210406161315-01-movie-theater-covid-0315-super-tease.jpg"/>
                            Film
                        </Card>
                        <Card onClick={toggleTimerPopup}>
                            <Image src="https://www.theladders.com/wp-content/uploads/timer-675x450.jpg"/>
                            Timer
                        </Card>
                        {timerPopup && <TimerPopup closePopup={toggleTimerPopup} />}
                        <Card onClick={() => customRequest('de')}>
                            <Image src="https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/1280px-Flag_of_Germany.svg.png"/>
                            Duitsland
                        </Card>
                    </Col>

                </Container>

                {isAdmin(me) && <div>
                    <ButtonWrapper>
                        <Button onClick={toggleAdminProgrammes}>Admin programma's</Button>
                    </ButtonWrapper>
                    {adminProgrammes && isAdmin(me) && <Container>
                        <Col>

                            <Card onClick={() => bakRequest(0)}>
                                <Image src="https://www.grolsch.com/files/pilsner-page/pilsner.webp"/>
                                {users[0].username} bak
                            </Card>
                        </Col>
                        <Col>
                            <Card onClick={() => bakRequest(1)}>
                                <Image src="https://www.grolsch.com/files/pilsner-page/pilsner.webp"/>
                                {users[users.length - 1].username} bak
                            </Card>
                        </Col>
                    </Container>
                    }
                </div>
                }

                <ButtonWrapper>
                    <Button onClick={toggleShow}>Kies een specifieke ledstrip</Button>
                </ButtonWrapper>
                {show && <CheckboxContainer>
                
                    <div>
                        <div style={{margin: "5px"}}>Main</div>
                        <input
                            type="checkbox"
                            checked={isCheckedMain}
                            onChange={handleOnChangeMain}
                        />
                    </div>

                    <div>
                    <div style={{margin: "5px"}}>Bar</div>
                        <input
                            type="checkbox"
                            checked={isCheckedBar}
                            onChange={handleOnChangeBar}
                        />
                    </div>

                    <div>
                    <div style={{margin: "5px"}}>Gang</div>
                        <input
                            type="checkbox"
                            checked={isCheckedHallway}
                            onChange={handleOnChangeHallway}
                        />
                    </div>
                
                </CheckboxContainer>}

                {isAdmin(me) && (
                    <ButtonWrapper>
                        <Button onClick={() => alert("Nog niet geïmplementeerd")}>Voeg programma toe</Button>
                    </ButtonWrapper>
                )}
               
            </Page>
        </PageWrapper>
    );

}

export default LedsPage;